import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import AttachmentIcon from "@mui/icons-material/Attachment"
import InfoIcon from "@mui/icons-material/Info"
import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Checkbox from "../Checkbox"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import InfiniteScroll from "../InfiniteScroll"
import EmptySplash from "../EmptySplash"
import {
  fetchEmailsList,
  fetchConversationThread,
  resetCommMessages,
  setActiveConversation,
  resetListViewState,
} from "../../state/commEmailListView"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"
import { DEFAULT_CONVERSATION_QUERY } from "../../util/constant"

import "./communications-email-list-view.sass"

interface Conversation {
  id: string,
  handleName: string,
  name: string,
  lastMessageName: string,
  date: string,
  subject: string,
  body: string,
  hasAttachment: boolean,
  unreadMessages: number,
  status?: GraphQL.ConversationEventType
}

type Props = {
  conversationFilter?: "inbox" | "sent" | "batch" | "draft"
  batchId?: string | null
}

export default function CommunicationsEmailListView({ conversationFilter = "inbox", batchId }: Props) {
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const {
    conversations, communicationMessages, conversationQuery,
  } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const [ isloading, setIsloading ] = React.useState(false)
  const [ processedConversations, setProcessedConversations ] = React.useState<Conversation[]>([])
  const [ activeCommunication, setActiveCommunication ] = React.useState<string | null>((API.isSuccess(communicationMessages))
    ? communicationMessages.payload.getConversation.id : null)

  const getCurrScrollPage = () => {
    if (processedConversations.length === 0) return 1
    return Math.ceil(processedConversations.length / DEFAULT_CONVERSATION_QUERY.LIMIT) + 1
  }

  const fetchConversations = (page?: number) => {
    if (commGroupID) {
      const conversationInfo: GraphQL.SearchConversationsQueryVariables = {
        communicationGroupId: commGroupID,
        startsWith: conversationQuery || DEFAULT_CONVERSATION_QUERY.STARTS_WITH,
        page: page || getCurrScrollPage(),
        limit: DEFAULT_CONVERSATION_QUERY.LIMIT,
        filter: conversationFilter,
        batchId,
      }
      dispatch(fetchEmailsList(conversationInfo))
    }
  }

  const clearLocalState = () => {
    // Clear the state
    setProcessedConversations([])
    setActiveCommunication(null)
    dispatch(resetListViewState())
  }

  useEffect(() => {
    if (conversations === "init") {
      fetchConversations(1)
    }

    return clearLocalState
  }, [])

  useEffect(() => {
    setIsloading(true)
    setProcessedConversations([])
    fetchConversations(1)
  }, [ conversationQuery ])

  const processConversations = (rows: GraphQL.ConversationRowFragment[]) => {
    const nextConversations = rows.map((conversation) => {
      let status: GraphQL.ConversationEventType | undefined
      if (conversation.conversationThread.lastMessage) {
        const { events } = conversation.conversationThread.lastMessage
        if (events.length > 0) status = events[events.length - 1].type
      }
      const data = {
        id: conversation.id,
        handleName: conversation.socialAccount?.userName,
        name: conversation.socialAccount?.name || "",
        lastMessageName: conversation.conversationThread?.lastMessage?.from.alias || "",
        date: formatTimestampToCustomDate(conversation.conversationThread?.lastMessage?.created),
        subject: conversation.subject,
        body: limitTextLength(conversation.conversationThread?.lastMessage?.text, 140),
        hasAttachment: conversation.conversationThread?.attachments?.length > 0,
        unreadMessages: conversation.conversationThread?.unreadCount,
        status,
      }

      // Return the data
      return data
    })
    if (getCurrScrollPage() === 1) {
      setIsloading(false)
      setProcessedConversations(nextConversations)
    } else {
      setIsloading(false)
      setProcessedConversations((prev) => [ ...prev, ...nextConversations ])
    }
  }

  const handleActiveCommunication = (id: string) => {
    // if converstation is loading, return
    if (communicationMessages === "loading") return

    // Toggle the active communication
    if (activeCommunication === id) {
      setActiveCommunication(null)
      dispatch(setActiveConversation(null))
      dispatch(resetCommMessages())
    } else {
      const conversation = processedConversations.find((conv) => conv.id === id)
      setActiveCommunication(id)
      dispatch(setActiveConversation(conversation))
      if (commGroupID) {
        dispatch(fetchConversationThread(id, commGroupID))
      }
    }
  }

  const getEmailClassName = (conversationId: string, unreadMessages: number) => {
    const isSelected = activeCommunication === conversationId
    return `email-list-email ${ isSelected ? "selected" : "" } ${ unreadMessages > 0 ? "has-unread-messages" : "" }`
  }

  const formatFromEmail = (conversation: Conversation) => {
    if (conversation.lastMessageName.length) return `${ conversation.handleName }...${ conversation.lastMessageName }`
    return conversation.handleName
  }

  useEffect(() => {
    if (conversations === "init") return

    if (conversations) {
      const onlyConversations = conversations.searchConversation.rows
        .filter((conversation) => conversation.__typename === "Conversation")

      processConversations(onlyConversations as GraphQL.ConversationRowFragment[])
    }
  }, [ conversations ])

  if (conversations === "init" || isloading) {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (conversationQuery !== "" && processedConversations.length === 0) {
    return (
      <div className="communications-email-list-view error">
        <EmptySplash headlineText="No messages matched your search" />
      </div>
    )
  }

  if (conversationFilter === "draft" && processedConversations.length === 0) {
    return (
      <div className="communications-email-list-view nodrafts">
        <p>There are no drafts for this communication group</p>
      </div>
    )
  }

  return (
    <div className="communications-email-list-view">
      <section className="email-list-heading"><Checkbox /></section>
      <div className="communications-email-list-infinite-scrolling">
        <InfiniteScroll
          dataLength={ processedConversations.length }
          next={ () => {
            fetchConversations()
          } }
          hasMore={ conversations.searchConversation.totalCount > processedConversations.length }
        >
          <section className="email-list-emails">
            { processedConversations.map((conversation, index) => (
              <div
                key={ conversation.id }
                onClick={ () => { handleActiveCommunication(conversation.id) } }
                id={ `cp_component-email-list-email${ activeCommunication === conversation.id ? "-selected" : "" }` }
                className={ getEmailClassName(conversation.id, conversation.unreadMessages) }
                onKeyDown={ (e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleActiveCommunication(conversation.id)
                  }
                } }
                role="button"
                tabIndex={ 0 }
              >
                <div className="email-list-email-selector">
                  <Checkbox id={ `email-cb-${ index }` } checked={ conversation.id === activeCommunication } />
                </div>
                <div className="email-content">
                  <div className="email-content-top">
                    {
                    (conversation.status && conversation.status === GraphQL.ConversationEventType.Failed)
                      ? (
                        <p className="handle-name message-failed">
                          { formatFromEmail(conversation) }
                          { conversation.unreadMessages > 0 && (
                            <span className="unread-message-qty">{ conversation.unreadMessages }</span>
                          ) }
                          <InfoIcon className="message-failed-icon" />
                        </p>
                      ) : (
                        <p className="handle-name">
                          { formatFromEmail(conversation) }
                          { conversation.unreadMessages > 0 && (
                            <span className="unread-message-qty">{ conversation.unreadMessages }</span>
                          ) }
                        </p>
                      )
                  }
                    <div className="email-content-top-right">
                      { conversation.hasAttachment && (
                      <div className="email-has-attachment"><AttachmentIcon className="email-attachment-icon" /></div>
                      ) }
                      <p className="date">{ conversation.date }</p>
                    </div>
                  </div>
                </div>
              </div>
            )) }
          </section>
        </InfiniteScroll>
      </div>
    </div>
  )
}
