import { ReactNode } from "react"
import { IndexRouteObject, NonIndexRouteObject } from "react-router-dom"
import * as GraphQL from "../graphql"

// HTTP Status Codes
type StatusOneHundreds = 100 | 101 | 102 | 103
type StatusTwoHundreds
  = 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 228

type StatusThreeHundreds
  = 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 306
  | 307
  | 308

type StatusFourHundreds
  = 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 418
  | 421
  | 422
  | 423
  | 424
  | 425
  | 426
  | 428
  | 429
  | 431
  | 451
  | 499 // Client Closed Request (Abort Signal)

type StatusFiveHundreds
  = 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511

export type ErrorStatusCode = StatusFourHundreds | StatusFiveHundreds

export type StatusCode
  = StatusOneHundreds
  | StatusTwoHundreds
  | StatusThreeHundreds
  | StatusFourHundreds
  | StatusFiveHundreds

// GraphQL API Status Types
export type Success<a> = {
  status: "success"
  payload: a
}

export type Err = {
  status: "error"
  statusCode?: ErrorStatusCode
  message?: string
}

export type Status<a>
  = "init"
  | "loading"
  | Success<a>
  | Err

// Feature Flag Types
// eslint-disable-next-line no-shadow
export enum FeatureFlag {
  CUSTOMER_MANAMGEMENT = "customer-management"
}

// Route Types
// eslint-disable-next-line no-shadow
export enum RootRoute {
  HOME = "dashboard",
  SEARCH = "search",
  SEARCH_AI = "search-ai",
  CAMPAIGNS = "campaigns",
  COMMS = "communications",
  CUSTOMERS = "customer-management",
  SOCIAL_PROFILES = "profile",
  SOCIAL_TRACKERS = "social-tracker",
  LISTS = "lists",
}

// All network array short cut for querying purposes
export const ALL_NETWORKS = [
  GraphQL.Network.Facebook,
  GraphQL.Network.Instagram,
  GraphQL.Network.Snapchat,
  GraphQL.Network.Tiktok,
  GraphQL.Network.Youtube,
]

export const LIST_SEARCH_TYPE_FILTERS = [
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.MyLists,
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Groups,
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Archived,
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Standard,
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Campaign,
  GraphQL.SuggestionListAndSuggestionListGroupSearchTypeFilter.Vetting,

]

export const CAMPAIGN_SEARCH_FILTERS = [
  GraphQL.SearchCampaignFilter.Archived,
  GraphQL.SearchCampaignFilter.Bookmarked,
  GraphQL.SearchCampaignFilter.MyCampaigns,
  GraphQL.SearchCampaignFilter.NotBookmarked,
  GraphQL.SearchCampaignFilter.Standard,
  GraphQL.SearchCampaignFilter.TiktokAll,
  GraphQL.SearchCampaignFilter.TiktokPublished,
  GraphQL.SearchCampaignFilter.TiktokUnpublished,
]

export const COMMUNICATION_SEARCH_FILTERS = [
  GraphQL.SearchCommunicationGroupFilter.Read,
  GraphQL.SearchCommunicationGroupFilter.Subscribed,
  GraphQL.SearchCommunicationGroupFilter.Unread,
  GraphQL.SearchCommunicationGroupFilter.Unsubscribed,
]

export const TEAM_MEMBERS_SEARCH_FILTERS = [
  GraphQL.SearchTeamMembersFilterCompareToOption.Name,
  GraphQL.SearchTeamMembersFilterCompareToOption.Title,
]

// // Miscellaneous Types
// Depricating use of this. Use GraphQL.Network type instead
export type _Network =
"instagram" |
"facebook" |
"tiktok" |
"youtube" |
"snapchat" |
"twitter"

// needed to map between internal Network type and GraphQL network type
// Depricating use of this. Use GraphQL.Network type instead
export const networkToGraphQLMap: {
  [key in _Network]: GraphQL.Network
} = {
  facebook: GraphQL.Network.Facebook,
  instagram: GraphQL.Network.Instagram,
  tiktok: GraphQL.Network.Tiktok,
  youtube: GraphQL.Network.Youtube,
  snapchat: GraphQL.Network.Snapchat,
  twitter: GraphQL.Network.Twitter,
}
export const graphQLtoNetworkMap: {
  [key in GraphQL.Network]: _Network
} = {
  [GraphQL.Network.Facebook]: "facebook",
  [GraphQL.Network.Instagram]: "instagram",
  [GraphQL.Network.Tiktok]: "tiktok",
  [GraphQL.Network.Youtube]: "youtube",
  [GraphQL.Network.Snapchat]: "snapchat",
  [GraphQL.Network.Twitter]: "twitter",
}

export type ProfileFlag = "VIP" | "Blocklisted" | "None"

export type NotificationStatus = "success" | "warning" | "error" | "support" | "info" | "inactive"

export type Toast = {
  type: NotificationStatus,
  message: string,
  additionalInfo?: ReactNode,
  timeout?: number,
}

export type Notification = {
  message: string,
}

export type Theme = "light" | "dark"

export type Timezone = [string, number]

export type ListCategory = GraphQL.SearchSuggestionListByIdQuery["suggestionListById"]["suggestionListCategories"][0]

export type ListCategorySocialAccount = ListCategory["suggestionListSocialAccounts"][0]

export interface Vertical {
  id: string;
  name: string;
}

export interface ProfileConnection extends GraphQL.PersonalitySocialAccountInput {
  profilePictureUrl: string
}

// SCOPES
// eslint-disable-next-line no-shadow
export enum Scope {
  // PLATFORM SCOPE //
  REACT_PLATFORM = "react_platform",

  // TEMPORARY DEVELOPMENT SCOPE //
  DEVELOPMENT = "development",
  ADMIN = "admin",
  BLOCKLISTED_PROFILES = "blocklisted_profiles",
  CAMPAIGN_MANAGEMENT = "campaign_management",
  CAMPAIGN_REPORTING = "campaign_reporting",
  CAMPAIGN_TYPE_OPTIONS = "campaign_type_options",
  COMMUNICATIONS_MANAGEMENT = "communications_management",
  COMMUNICATIONS_TEMPLATE_MANAGEMENT = "communications_template_management",
  COMMUNICATIONS_UNASSIGNED = "communications_unassigned",
  COMMUNICATIONS_VIEW = "communications_view",
  ENGAGEMENT_BY_POST_TYPE = "engagement_by_post_type",
  FEATURE_CAMPAIGN = "feature_campaign",
  FEATURE_COMMUNICATIONS = "feature_communications",
  FEATURE_CUSTOMER_MANAGEMENT = "feature_customer_management",
  FEATURE_DISPLAY_AUDIENCE_AUTHENTICITY = "feature_display_audience_authenticity",
  FEATURE_DISPLAY_AUDIENCE_DATA = "feature_display_audience_data",
  FEATURE_DISPLAY_AUDIENCE_QUALITY = "feature_display_audience_quality",
  FEATURE_ENABLE_FACEBOOK = "feature_enable_facebook",
  FEATURE_ENABLE_SNAPCHAT = "feature_enable_snapchat",
  FEATURE_ENABLE_INSTAGRAM = "feature_enable_instagram",
  FEATURE_ENABLE_TIKTOK = "feature_enable_tiktok",
  FEATURE_ENABLE_YOUTUBE = "feature_enable_youtube",
  FEATURE_INFLUENCERS = "feature_influencers",
  FEATURE_INFLUENCER_LIST = "feature_influencer_list",
  FEATURE_LIST_DEMOGRAPHIC_SCORE = "feature_lists_demographic_score",
  FEATURE_LIST_ISCORE = "feature_lists_iscore",
  FEATURE_LIST_TTCM_ENGAGEMENT_RATE = "feature_list_ttcm_engagement_rate",
  FEATURE_SEARCH = "feature_search",
  FEATURE_SEARCH_AI = "feature_search_ai",
  FEATURE_SEARCH_AUDIENCE = "feature_search_audience",
  FEATURE_SEARCH_AUDIENCE_ETHNICITY = "feature_search_audience_ethnicity",
  FEATURE_SEARCH_AUDIENCE_LOCATION = "feature_search_audience_location",
  FEATURE_SEARCH_BRAND_LOGO = "feature_search_brand_logo",
  FEATURE_SEARCH_CONTACT_INFO = "feature_search_contact_info",
  FEATURE_SEARCH_CUS_SOCIAL_ACCOUNTS = "feature_search_cus_social_accounts",
  FEATURE_SEARCH_INFLUENCER = "feature_search_influencer",
  FEATURE_SEARCH_IN_DEMO = "feature_search_in_demo",
  FEATURE_SEARCH_ISCORE = "feature_search_iscore",
  FEATURE_SEARCH_TIN_SOCIAL_ACCOUNTS = "feature_search_tin_social_accounts",
  FEATURE_SOCIAL_TRACKER = "feature_social_tracker",
  FEATURE_USER = "feature_user",
  FEATURE_TIKTOK = "feature_tiktok",
  INFLUENCERS_CREATE = "influencers_create",
  INFLUENCERS_CUS = "feature_influencers_cus",
  INFLUENCERS_DELETE = "influencers_delete",
  INFLUENCERS_READ = "influencers_read",
  INFLUENCERS_UPDATE = "influencers_update",
  INFLUENCER_MANAGER_CREATE = "influencer_manager_create",
  INFLUENCER_MANAGER_DELETE = "influencer_manager_delete",
  INFLUENCER_MANAGER_READ = "influencer_manager_read",
  INFLUENCER_MANAGER_UPDATE = "influencer_manager_update",
  NSFW_FLAG = "nsfw_flag",
  OAUTH_ACCOUNTS = "oauth_accounts",
  PROFILE_TYPE = "profile_type",
  SCORE_AD_COUNCIL = "score_ad_council",
  SOCIAL_ACCOUNT_ADDED = "social_account_added",
  SOCIAL_ACCOUNT_CONTACT_INFO = "social_account_contact_info",
  SOCIAL_ACCOUNT_FACEBOOK_CREATE = "social_account_assoc_facebook_create",
  SOCIAL_ACCOUNT_FACEBOOK_DELETE = "social_account_assoc_facebook_delete",
  SOCIAL_ACCOUNT_INSTAGRAM_CREATE = "social_account_assoc_instagram_create",
  SOCIAL_ACCOUNT_INSTAGRAM_DELETE = "social_account_assoc_instagram_delete",
  SOCIAL_ACCOUNT_LAST_UPDATED = "social_account_last_updated",
  SOCIAL_ACCOUNT_SNAPCHAT_CREATE = "social_account_assoc_snapchat_create",
  SOCIAL_ACCOUNT_SNAPCHAT_DELETE = "social_account_assoc_snapchat_delete",
  SOCIAL_ACCOUNT_TIKTOK_CREATE = "social_account_assoc_tiktok_create",
  SOCIAL_ACCOUNT_TIKTOK_DELETE = "social_account_assoc_tiktok_delete",
  SOCIAL_ACCOUNT_YOUTUBE_CREATE = "social_account_assoc_youtube_create",
  SOCIAL_ACCOUNT_YOUTUBE_DELETE = "social_account_assoc_youtube_delete",
  SOCIAL_TRACKER_DOWNLOAD = "social_tracker_download",
  SUGGESTION_LIST_MODE = "suggestion_list_mode",
  TCM_CAMPAIGN_MANAGEMENT = "tcm_campaign_management",
  USER_UPDATE = "user_update",
  VIP_PROFILES = "vip_profiles",
  WORKED_WITH = "worked_with",
}

export interface FileResponse {
  id: number | string,
  url: string
}

export const defaultListToggles = {
  // Insights
  displayInsightsEngagementRateOverTime: false,
  displayInsightsEngagementsByPostType: false,
  displayInsightsEngagementsByPostTypeReel: false,
  displayInsightsEngagementsHeatMap: false,
  displayInsightsRecentMediaAIImageAnalysis: false,
  displayInsightsBrandsMentionedBrandsWorkedWith: false,
  displayInsightsBrandsDetected: false,
  displayInsightsPITraits: false,
  // Audience
  displayAudienceBaseline: false,
  displayFollowersOverTime: false,
  displayAudienceQuality: false,
  displayGender: false,
  displayFamilyStatus: false,
  displayAgeRange: false,
  displayIncomeRange: false,
  displayEducationLevel: false,
  displayEthnicities: false,
  displayLanguages: false,
  displayReligions: false,
  displayCountries: false,
  displayStates: false,
  displayCities: false,
  displayOccupations: false,
  displayIndustries: false,
  displayEmployers: false,
  displayUniversities: false,
  displayAffinities: false,
  // Content
  displayTopPosts: false,
  displayKeywords: false,
}

export type ListTogglesType = {
  // Insights
  displayInsightsEngagementRateOverTime: boolean
  displayInsightsEngagementsByPostType: boolean
  displayInsightsEngagementsByPostTypeReel: boolean
  displayInsightsEngagementsHeatMap: boolean
  displayInsightsRecentMediaAIImageAnalysis: boolean
  displayInsightsBrandsMentionedBrandsWorkedWith: boolean
  displayInsightsBrandsDetected: boolean
  displayInsightsPITraits: boolean
  // Audience
  displayAudienceBaseline: boolean
  displayFollowersOverTime: boolean
  displayAudienceQuality: boolean
  displayGender: boolean
  displayFamilyStatus: boolean
  displayAgeRange: boolean
  displayIncomeRange: boolean
  displayEducationLevel: boolean
  displayEthnicities: boolean
  displayLanguages: boolean
  displayReligions: boolean
  displayCountries: boolean
  displayStates: boolean
  displayCities: boolean
  displayOccupations: boolean
  displayIndustries: boolean
  displayEmployers: boolean
  displayUniversities: boolean
  displayAffinities: boolean
  // Content
  displayTopPosts: boolean
  displayKeywords: boolean
}

export type DeliverableCreativeThemes = "Lifestyle"|
"Comedy"|
"Food"|
"Fashion"|
"Sports"|
"DIY"|
"Music"

export type MapAllToggles = { [key: string]: GraphQL.CampaignReportToggleSettingInput }
export interface CampaignReportUpdate {
  campaignId: string,
  template: string,
  name: string,
  enabled: boolean,
  clientEnabled: boolean,
  clientBoosted: boolean,
  toggleSettings: MapAllToggles
}

export type DeliverablePotsType = "CAROUSEL" | "IN_FEED" | "STORY"

export type DeliverableStatus = "AWAITING_CONTENT" | "FINALIZED" | "LIVE" | "PENDING" | "UPLOADED"

// eslint-disable-next-line no-shadow
export enum SuggestionListContentTabPathsEnum {
  TOP_POSTS = "top-posts",
  KEYWORDS = "relevant-posts",
  IMAGE_TAGS = "image-tags-posts",
}

export interface ScoreBreakDown {
  socialAccount: GraphQL.SocialAccount;
  scores: GraphQL.Score[];
}

interface RadiusIndexRouteObject extends IndexRouteObject {
  handle?: {
    scopes?: Scope[]
  }
}
interface RadiusNonIndexRouteObject extends NonIndexRouteObject {
  handle?: {
    scopes?: Scope[]
  }
}
export type RadiusRouteObject = RadiusIndexRouteObject | RadiusNonIndexRouteObject

// eslint-disable-next-line no-shadow
export enum SearchAIType {
  Accounts,
  Content
}
export interface MediaContent {
  id: string;
  byte_size: number;
  folder: string;
  region: string;
  bucket: string;
  file_hash: string;
  detected_mime_type: string;
  url: string;
  record_hash: string;
  extension: string;
  network: string;
}

export interface MediaThumbnail {
  id: string;
  byte_size: number;
  folder: string;
  region: string;
  bucket: string;
  file_hash: string;
  detected_mime_type: string;
  url: string;
  record_hash: string;
  extension: string;
  network: string;
}

export interface MediaObject {
  id: string;
  content: MediaContent;
  thumbnail: MediaThumbnail;
  name: string;
  size: number;
}

export type PillLabelColor = {
  id: string
  label: string
  colorIndex: number
  type: "Default" | "Custom"
}

export type CommunicationDraftReciptiant = {
  email: string
  unsubscribed: boolean
  socialAccountId: string
  socialAccountUsername: string
}

export type CommunicationDraftRichTextEditorState = {
  attachmentHistory: string[]
  attachementHistoryIndex: number
  attachments: GraphQL.ConversationAttachmentInput[]
  content: string
}

export type CommunicationDraftData = {
  mode: GraphQL.CommunicationGroupDraftType
  toList: CommunicationDraftReciptiant[]
  ccList: CommunicationDraftReciptiant[]
  enableUploadLink: boolean
  subject: string
  template: string | null
  richTextEditorState: CommunicationDraftRichTextEditorState
}
