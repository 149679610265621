import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import "./modal-campaign-association-warning.sass"
import { addUserToCampaign, closeNoUserAssociatedWithCampaignModalOpen } from "../../state/ModalCreateDeliverableRequirements"
import { pushToast } from "../../state/toastSlice"
import { openModalCreateDeliverable } from "../../state/ModalCreateDeliverable"
import { isSuccess } from "../../util/apiClient"
import LoadingIndicator from "../LoadingIndicator"

export default function ModalAddUserToCampaign() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalAddUserToCampaign" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const [ isLoading, setIsLoading ] = useState(false)

  const {
    noUserAssociatedWithCampaignModalOpen: open, user, campaign,
  } = useSelector(({ modalCreateDeliverableRequirements }) => modalCreateDeliverableRequirements)
  const { communicationMessages } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)

  const dispatch = useDispatch()

  if (!user || !campaign) return null

  const handleClose = () => {
    dispatch(closeNoUserAssociatedWithCampaignModalOpen())
  }

  const handleSubmit = async () => {
    if (isSuccess(communicationMessages)) {
      setIsLoading(true)
      await dispatch(addUserToCampaign({
        variables: {
          campaignId: campaign.id,
          networkAccountId: user.id,
        },
        onSuccess: () => {
          dispatch(pushToast({
            message: translate("Creator added to campaign successfully!"),
            type: "success",
          }))
          dispatch(openModalCreateDeliverable({ conversation: communicationMessages.payload.getConversation }))
          handleClose()
        },
        onError: () => {
          dispatch(pushToast({
            message: tCommon("There was an error. Please try again!"),
            type: "error",
          }))
        },
      }))
      setIsLoading(false)
    }
  }

  return (
    <Modal
      className="cp_component_modal-campaign-association-warning"
      title={ translate("Create Deliverable") }
      primaryLabel={ !isLoading ? translate("Add To Campaign") : <LoadingIndicator /> }
      disabled={ isLoading }
      primaryAction={ handleSubmit }
      secondaryLabel={ tCommon("Cancel") }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
    >
      <p className="modal-notification-text">
        { translate("Oops, it doesn't look like") }
        { " " }
        <b>{ user.name }</b>
        { " " }
        { translate("is associated with") }
        { " " }
        <b>{ campaign.name }</b>
      </p>
    </Modal>
  )
}
