import React from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import * as API from "../../../util/apiClient"
import LoadingIndicator from "../../LoadingIndicator"
import { useDispatch, useSelector } from "../../../state/hooks"
import Button from "../../Button"
import CommunicationsTableWrapper from "./CommunicationsTableWrapper"
import ErrorHandler from "../../ErrorHandler"
import "./account-communications-wrapper.sass"
import { openModalAddToCommunications } from "../../../state/modalAddToCommunications"
import { Scope } from "../../../util/types"

export default function Comms() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const dispatch = useDispatch()

  const { scopes } = useSelector(({ user }) => user)
  const { listProfile } = useSelector(({ listSocialProfile }) => listSocialProfile)

  if (listProfile === "init" || listProfile === "loading") {
    return <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
  }

  if (API.isError(listProfile)) {
    return <ErrorHandler />
  }

  const onButtonClick = () => {
    const { id, network } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount
    dispatch(openModalAddToCommunications({
      socialAccountId: id,
      socialAccountNetwork: network,
    }))
  }

  return (
    <div className="cp_component_list-profile-tabs_communications">
      <div className="content-header">
        <h5>{ translate("Communications") }</h5>
        { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
          <Button
            className="add-to-group-button"
            label={ translate("Add to group") }
            isEnabled={ true }
            isPrimary={ true }
            onClick={ onButtonClick }
          />
        ) }
      </div>
      <CommunicationsTableWrapper />
    </div>
  )
}
