import { SetURLSearchParams } from "react-router-dom"
import React from "react"
import * as GraphQL from "../../graphql/search"
import { Scope } from "../../util/types"

// TYPES
interface AIPrompt {
  id: number
  value: string
}

export type AgeRangeValue = {
  minAge: number | null,
  maxAge: number | null,
}

export type AgeMark = {
  value: number,
  label?: React.ReactNode,
  ageGroupIndex: number,
  ageGroup: GraphQL.Age_Groups
  tooltipLabel: string
}

export type SearchInput = {
  limit: number,
  page: number,
  username?: string,
  selectedPostIds: string[],
  selectedAccountIds: string[],
  socialNetworks: GraphQL.Network[],
  prompts: AIPrompt[],
  profileParams: GraphQL.ProfileFiltersInput,
  contentParams: GraphQL.ContentFiltersInput,
  audienceParams: GraphQL.AudienceFiltersInput,
}

// CONSTANTS
export const AgeGroups: GraphQL.Age_Groups[] = [
  GraphQL.Age_Groups.Age_17AndUnder,
  GraphQL.Age_Groups.Age_18To_20,
  GraphQL.Age_Groups.Age_21To_24,
  GraphQL.Age_Groups.Age_25To_29,
  GraphQL.Age_Groups.Age_30To_34,
  GraphQL.Age_Groups.Age_35To_44,
  GraphQL.Age_Groups.Age_45To_54,
  GraphQL.Age_Groups.Age_55To_64,
  GraphQL.Age_Groups.Age_65AndOver,
]

// the age slider min and max need to be in the range of 1 to 100. this is a helper functions and structures to translate
// the age groups from the slider markers

export const getAgeGroups = (minAge: number, maxAge: number): GraphQL.Age_Groups[] => {
  const minIndex = AGE_MARKS.find((mark) => mark.value === minAge)?.ageGroupIndex || 0
  const maxIndex = AGE_MARKS.find((mark) => mark.value === maxAge)?.ageGroupIndex || AgeGroups.length - 1
  return AgeGroups.slice(minIndex, maxIndex + 1)
}

export const AGE_MARKS: AgeMark[] = [
  {
    value: 1,
    label: "< 18",
    ageGroupIndex: 0,
    ageGroup: GraphQL.Age_Groups.Age_17AndUnder,
    tooltipLabel: "< 18",
  },
  {
    value: 15,
    ageGroupIndex: 1,
    ageGroup: GraphQL.Age_Groups.Age_18To_20,
    tooltipLabel: "18-20",
  },
  {
    value: 30,
    ageGroupIndex: 2,
    ageGroup: GraphQL.Age_Groups.Age_21To_24,
    tooltipLabel: "21-24",
  },
  {
    value: 43,
    ageGroupIndex: 3,
    ageGroup: GraphQL.Age_Groups.Age_25To_29,
    tooltipLabel: "25-29",
  },
  {
    value: 55,
    ageGroupIndex: 4,
    ageGroup: GraphQL.Age_Groups.Age_30To_34,
    tooltipLabel: "30-34",
  },
  {
    value: 66,
    ageGroupIndex: 5,
    ageGroup: GraphQL.Age_Groups.Age_35To_44,
    tooltipLabel: "35-44",
  },
  {
    value: 77,
    ageGroupIndex: 6,
    ageGroup: GraphQL.Age_Groups.Age_45To_54,
    tooltipLabel: "45-54",
  },
  {
    value: 88,
    ageGroupIndex: 7,
    ageGroup: GraphQL.Age_Groups.Age_55To_64,
    tooltipLabel: "55-64",
  },
  {
    value: 100,
    label: "65+",
    ageGroupIndex: 8,
    ageGroup: GraphQL.Age_Groups.Age_65AndOver,
    tooltipLabel: "65+",
  },
]

export const LIMIT_DEFAULT = 50

export const INCOME_BRACKETS: GraphQL.IncomeBrackets[] = [
  GraphQL.IncomeBrackets.Under_10000,
  GraphQL.IncomeBrackets["10000_19999"],
  GraphQL.IncomeBrackets["20000_29999"],
  GraphQL.IncomeBrackets["30000_39999"],
  GraphQL.IncomeBrackets["40000_49999"],
  GraphQL.IncomeBrackets["50000_74999"],
  GraphQL.IncomeBrackets["75000_100000"],
  GraphQL.IncomeBrackets["100000Above"],
]

export const NETWORKS_AVAILABLE = [
  GraphQL.Network.Facebook,
  GraphQL.Network.Instagram,
  GraphQL.Network.Snapchat,
  GraphQL.Network.Tiktok,
  GraphQL.Network.Youtube,
]

export const NETWORK_REQUIRED_SCOPES: {[key in GraphQL.Network]: Scope[]} = {
  [GraphQL.Network.Facebook]: [ Scope.FEATURE_ENABLE_FACEBOOK ],
  [GraphQL.Network.Instagram]: [ Scope.FEATURE_ENABLE_INSTAGRAM ],
  [GraphQL.Network.Snapchat]: [ Scope.FEATURE_ENABLE_SNAPCHAT ],
  [GraphQL.Network.Tiktok]: [ Scope.FEATURE_ENABLE_TIKTOK ],
  [GraphQL.Network.Youtube]: [ Scope.FEATURE_ENABLE_YOUTUBE ],
}

// eslint-disable-next-line no-shadow
export enum SearchFieldString {
  // These are strings that the backend uses to recognize search fields.
  // For instance, these strings are used as the `sortBy` value in the
  // search input.

  // NOTE: Being able to sort by Engagement Rate is currently not supported
  // on the backend. Once that becomes a possibility, Engagement Rate may be
  // re-added to the switch case below.

  AdCouncilScore = "ad-council-score", // Ad Council and IScore are the same value
  AudienceQualityScore = "aud-quality-score",
  DemographicScore = "demographic-score",
  EngagementScore = "engagement-rate-score",
  Followers = "followers",
  IScore = "i-score",
  InDemoPercentage = "in-demo",
  Matches = "matches",
  EngagementRate = "engagement-rate",
  OrganicEngagementRate = "organic-engagement-rate",
  PostDate = "date-posted",
  SponsoredEngagementRate = "sponsored-engagement-rate",
}

// eslint-disable-next-line no-shadow
export enum SearchColumn {
  Account = "account",
  AdCouncilScore = "adCouncilScore",
  AudienceQualityScore = "audienceQualityScore",
  Summary = "summary",
  Biography = "biography",
  DemographicScore = "demographicScore",
  EllipsisMenu = "ellipsisMenu",
  EngagementRate = "engagementRate",
  EngagementScore = "engagementScore",
  Followers = "followers",
  IScore = "iScore",
  InDemoPercentage = "inDemoPercentage",
  Matches = "matches",
  Media = "media",
  OrganicEngagementRate = "organicEngagementRate",
  PostDate = "postDate",
  PostDetails = "postDetails",
  PostType = "postType",
  SponsoredEngagementRate = "sponsoredEngagementRate",
}

export function initialContentInput(): GraphQL.ContentFiltersInput {
  return {
    keywords: [],
    minPostEngagement: null,
  }
}

export const initialProfileInput = (): GraphQL.ProfileFiltersInput => ({
  bioKeywords: [],
  maxFollowers: null,
  minEngagement: null,
  minFollowers: 10000,
})

export const initialAudienceInput = (): GraphQL.AudienceFiltersInput => ({
  ageGroups: [],
  ageMinimum: 0,
  locationMinimum: [],
  income: [],
  familyMarriedMinimum: null,
  familyParentsMinimum: null,
  familySingleMinimum: null,
  ethnicityAfricanAmericanMinimum: null,
  ethnicityAsianPacificIslanderMinimum: null,
  ethnicityHispanicLatinoMinimum: null,
  ethnicityWhiteCaucasianMinimum: null,
  genderFemaleMinimum: null,
  genderMaleMinimum: null,
})

export const initialSearchState = (): SearchInput => ({
  username: "",
  limit: LIMIT_DEFAULT,
  page: 1,
  selectedAccountIds: [],
  selectedPostIds: [],
  socialNetworks: [
    GraphQL.Network.Instagram,
    GraphQL.Network.Youtube,
  ],
  prompts: [],
  contentParams: initialContentInput(),
  profileParams: initialProfileInput(),
  audienceParams: initialAudienceInput(),
})

// SEARCH HELPER MISCELLANEOUS FUNCTIONS

export function cloneSearchInput(input: SearchInput): SearchInput {
  return JSON.parse(JSON.stringify(input))
}

// QUERY PARAMETER HELPERS
export const QUERY_PARAM_Q = "q"
export const QUERY_PARAM_INFLUENCER = "influencer"
export const QUERY_PARAM_AUDIENCE = "audience"
export const QUERY_PARAM_CONTENT = "content"

export function baseQueryToSearchInput(q: string): Partial<SearchInput> {
  const queryValues = JSON.parse(q)
  const validQueryValues: Partial<SearchInput> = {}
  const excludedSearchInputFields = [
    "audienceParams",
    "contentParams",
    "influencerParams",
  ]

  const searchInputFields = Object
    .keys(initialSearchState())
    .filter((k) => !excludedSearchInputFields.includes(k))

  Object
    .keys(queryValues)
    .filter((k) => searchInputFields.includes(k))
    .forEach((k) => {
      validQueryValues[k as keyof SearchInput] = queryValues[k]
    })

  return validQueryValues
}

export function baseQueryToProfileInput(
  profile: string,
): Partial<GraphQL.ProfileFiltersInput> {
  const profileValues = JSON.parse(profile)
  const validProfileValues: Partial<GraphQL.ProfileFiltersInput> = {}
  const profileInputFields = Object.keys(initialProfileInput())

  Object
    .keys(profileValues)
    .filter((k) => profileInputFields.includes(k))
    .forEach((k) => {
      validProfileValues[
        k as keyof GraphQL.ProfileFiltersInput
      ] = profileValues[k]
    })

  return validProfileValues
}

export function baseQueryToContentInput(
  content: string,
): Partial<GraphQL.ContentFiltersInput> {
  const contentValues = JSON.parse(content)
  const validContentValues: Partial<GraphQL.ContentFiltersInput> = {}
  const contentInputFields = Object.keys(initialContentInput())

  Object
    .keys(contentValues)
    .filter((k) => contentInputFields.includes(k))
    .forEach((k) => {
      validContentValues[
        k as keyof GraphQL.ContentFiltersInput
      ] = contentValues[k]
    })

  return validContentValues
}

export function baseQueryToAudienceInput(
  audience: string,
): Partial<GraphQL.AudienceFiltersInput> {
  const audienceValues = JSON.parse(audience)
  const validAudienceValues: Partial<GraphQL.AudienceFiltersInput> = {}
  const audienceInputFields = Object.keys(initialAudienceInput())

  Object
    .keys(audienceValues)
    .filter((k) => audienceInputFields.includes(k))
    .forEach((k) => {
      validAudienceValues[
        k as keyof GraphQL.AudienceFiltersInput
      ] = audienceValues[k]
    })

  return validAudienceValues
}

export function setSearchInputQueryParams(
  input: SearchInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialSearchState()
  const q: Partial<SearchInput> = {}

  if (Array.isArray(input.prompts) && input.prompts.length > 0) {
    q.prompts = input.prompts
  }

  // ONLY include values as query string parameters if they vary from the
  // initial search input state.
  if (!init.socialNetworks.every((s) => input.socialNetworks.includes(s))) {
    q.socialNetworks = input.socialNetworks
  }

  if (input.username !== null && typeof input.username === "string") {
    q.username = input.username
  }

  // Set `q` query param in URL
  if (JSON.stringify(q) === "{}") {
    searchParams.delete(QUERY_PARAM_Q)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_Q, JSON.stringify(q))
  setSearchParams(searchParams)
}

export function setProfileInputQueryParams(
  input: GraphQL.ProfileFiltersInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialProfileInput()
  const profile: Partial<GraphQL.ProfileFiltersInput> = {}

  if (input.maxFollowers !== init.maxFollowers) {
    profile.maxFollowers = input.maxFollowers
  }

  if (input.minEngagement !== init.minEngagement) {
    profile.minEngagement = input.minEngagement
  }

  if (input.minFollowers !== init.minFollowers) {
    profile.minFollowers = input.minFollowers
  }

  // Set `influencer` query param in URL
  if (JSON.stringify(profile) === "{}") {
    searchParams.delete(QUERY_PARAM_INFLUENCER)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_INFLUENCER, JSON.stringify(profile))
  setSearchParams(searchParams)
}

export function setContentInputQueryParams(
  input: GraphQL.ContentFiltersInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const init = initialContentInput()
  const content: Partial<GraphQL.ContentFiltersInput> = {}

  if (Array.isArray(input.keywords) && input.keywords.length > 0) {
    content.keywords = input.keywords
  }

  if (input.minPostEngagement !== init.minPostEngagement) {
    content.minPostEngagement = input.minPostEngagement
  }

  // Set `content` query param in URL
  if (JSON.stringify(content) === "{}") {
    searchParams.delete(QUERY_PARAM_CONTENT)
    setSearchParams(searchParams)
    return
  }
  searchParams.set(QUERY_PARAM_CONTENT, JSON.stringify(content))
  setSearchParams(searchParams)
}

export function setAudienceInputQueryParams(
  input: GraphQL.AudienceFiltersInput,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
): void {
  const audience: Partial<GraphQL.AudienceFiltersInput> = {}

  if (input.ethnicityAfricanAmericanMinimum != null) {
    audience.ethnicityAfricanAmericanMinimum = input.ethnicityAfricanAmericanMinimum
  }

  if (input.ethnicityAsianPacificIslanderMinimum != null) {
    audience.ethnicityAsianPacificIslanderMinimum = input.ethnicityAsianPacificIslanderMinimum
  }

  if (input.ethnicityHispanicLatinoMinimum != null) {
    audience.ethnicityHispanicLatinoMinimum = input.ethnicityHispanicLatinoMinimum
  }

  if (input.ethnicityWhiteCaucasianMinimum != null) {
    audience.ethnicityWhiteCaucasianMinimum = input.ethnicityWhiteCaucasianMinimum
  }

  if (input.familySingleMinimum != null) {
    audience.familySingleMinimum = input.familySingleMinimum
  }

  if (input.familyParentsMinimum != null) {
    audience.familyParentsMinimum = input.familyParentsMinimum
  }

  if (input.familyMarriedMinimum != null) {
    audience.familyMarriedMinimum = input.familyMarriedMinimum
  }

  if (input.genderMaleMinimum != null) {
    audience.genderMaleMinimum = input.genderMaleMinimum
  }

  if (input.genderFemaleMinimum != null) {
    audience.genderFemaleMinimum = input.genderFemaleMinimum
  }

  if (Array.isArray(input.income) && input.income.length > 0) {
    audience.income = input.income
  }

  if (Array.isArray(input.locationMinimum) && input.locationMinimum.length > 0) {
    audience.locationMinimum = input.locationMinimum
  }

  if (input.ageGroups.length !== AgeGroups.length) {
    audience.ageGroups = input.ageGroups
  }

  if (input.ageMinimum != null) {
    audience.ageMinimum = input.ageMinimum
  }

  // Set `audience` query param in URL
  if (JSON.stringify(audience) === "{}") {
    searchParams.delete(QUERY_PARAM_AUDIENCE)
    setSearchParams(searchParams)
    return
  }

  searchParams.set(QUERY_PARAM_AUDIENCE, JSON.stringify(audience))
  setSearchParams(searchParams)
}
