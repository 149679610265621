import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Container } from "@mui/material"
import TextField from "@mui/material/TextField"
import {
  gql,
  useMutation,
  useQuery,
} from "@apollo/client"
import Modal from "../../../../../Modal"
import Autocomplete from "../../../../../Autocomplete"

const QUERY = gql`
  query ModalAddCategoriesToCampaign {
    campaignCategoryOptions {
      id
      categoryNames
    }
  }
`

const UPSERT_MUTATION = gql`
  mutation ModalAddCategoriesToCampaign_upsert($input: UpsertCategoriesToCampaignInput!) {
    upsertCategoriesToCampaign(input: $input) {
      campaignCategories {
          id
          categoryNames
      }
    }
  }
`

type Props = {
  campaignId: string;
  open: boolean;
  onClose: () => void;
};

export default function ModalAddCategoriesToCampaign({
  campaignId, open, onClose,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalAddCategoriesToCampaign" })

  const query$ = useQuery<{
    campaignCategoryOptions: {
      id: string;
      categoryNames: string[];
    };
  }>(QUERY, {
    skip: !open,
    fetchPolicy: "network-only",
  })

  const [ upsertCategories, upsertCategories$ ] = useMutation(UPSERT_MUTATION)

  const [ selectedOptions, setSelectedOptions ] = useState<string[]>([])

  const options: string[] = query$.data?.campaignCategoryOptions.categoryNames || []

  useEffect(() => {
    if (!open) {
      setSelectedOptions([])
    }
  }, [ open ])

  const handleSubmit = async () => {
    await upsertCategories({
      variables: {
        input: {
          campaignId,
          categoryNames: selectedOptions,
        },
      },
    })

    onClose()
  }

  return (
    <Modal
      data-testid="add-categories-to-campaign-modal"
      className="cp_component_modal-add-to"
      title={ translate("Add Categories") }
      subtitle={ translate("Assign category tags to classify this campaign") }
      primaryAction={ handleSubmit }
      secondaryAction={ onClose }
      closeAction={ onClose }
      primaryLabel={ translate("Add") }
      secondaryLabel={ translate("Cancel") }
      disabled={ selectedOptions.length === 0 || upsertCategories$.loading }
      disablePortal={ true }
      hasSecondaryButton={ true }
      open={ open }
    >
      <Container className="cp_component_modal-add-to-container">
        <Box py={ 2 }>
          <Autocomplete
            data-testid="categories-field"
            autoFocus={ true }
            freeSolo={ true }
            loading={ query$.loading }
            multiple={ true }
            options={ options }
            value={ selectedOptions }
            onChange={ (_event, value) => setSelectedOptions(value as string[]) }
            renderInput={ (params) => (
              <TextField { ...params } label={ translate("Categories") } placeholder={ translate("Enter Category Name") } />
            ) }
          />
        </Box>
      </Container>
    </Modal>
  )
}
